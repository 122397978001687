import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faChevronRight, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import _ from 'lodash';
import './OpportunityResult.scss';

import { getBrand } from '../../Helpers/user_helpers';
import {
  getStatsOnOpportunity,
  getSpendDataOnOpportunity,
  getRecentActivityOnOpportunity,
  getTimingOnOpportunity,
  getDisplayStatsOnOpportunity
} from '../../Helpers/opportunity_helpers';
import { getPrettyNumber, getPrettyTimeAgoFromNow } from '../../Helpers/formatting';

import { ActionsOverlay } from '../Modals/ActionsOverlay';
import Loader from '../Loader/Loader';
import Tooltip from '../General/Tooltip';

const OpportunityResult = props => {
  const { user, opportunity, analytics, isFetchingRequests } = props;
  const { id, statsLastUpdatedAt, preview_images } = opportunity;
  const brand = getBrand(user);

  const history = useHistory();
  const title = opportunity.title || 'New Opportunity';

  // Check Timeline
  const timing = getTimingOnOpportunity(opportunity);
  const { isActive, isNotYetStarted, percentComplete, timelineLabel } = timing;
  const showTimeline = isActive || isNotYetStarted;

  // Get dynamic images to show
  const images = _.filter(
    _.concat(
      ...(timing.isActive ? preview_images || [] : []).map(data => ({ image: data.image, timestamp: data.contentPublishedAt })),
      { image: opportunity.coverImage || brand.mobileBadgeImage, timestamp: null },
      { image: opportunity.coverImage || brand.mobileBadgeImage, timestamp: null },
      { image: opportunity.coverImage || brand.mobileBadgeImage, timestamp: null }
    )
  );
  const imageData = images[0];
  const image2Data = images[1] || images[0];
  const image3Data = images[2] || images[1] || images[0];
  const validImageData = [imageData, image2Data, image3Data].filter(i => i);
  const imageCount = validImageData.length;

  // Talent Statistics
  const spendData = getSpendDataOnOpportunity(analytics, opportunity);
  const statsData = getStatsOnOpportunity(analytics, opportunity);
  const resultStats = getDisplayStatsOnOpportunity(opportunity);

  // Grid Statistics
  const requestStats = [
    { label: 'Accepted', value: statsData.num_accepted, className: 'accepted' },
    { label: 'Outstanding', value: statsData.num_outstanding, className: 'pending' },
    { label: 'Completed', value: statsData.num_completed, className: 'completed' },
    { label: 'Declined', value: statsData.num_dismissed, className: 'declined' }
  ];

  // Activity
  const activity = getRecentActivityOnOpportunity(analytics, opportunity);

  // Action Handling
  const [isDuplicatingOpportunity, setIsDuplicatingOpportunity] = React.useState(false);

  // Actions
  const actionOptions = [
    {
      display: 'Delete Opportunity',
      disabled: statsData.num_accepted > 0,
      tooltip: statsData.num_accepted > 0 ? 'You cannot delete an opportunity with accepted requests' : null,
      icon: 'faTrash',
      isPerformingAction: false,
      onClick: () => {
        confirmAlert({
          title: 'Just Confirming?',
          message: 'Are you sure you want to delete this opportunity?',
          buttons: [
            { label: 'Cancel', className: 'cancel' },
            { label: 'Yes', onClick: () => props.deleteOpportunity(opportunity) }
          ]
        });
      }
    },
    {
      display: 'Edit Opportunity',
      disabled: false,
      tooltip: null,
      icon: 'faEdit',
      isPerformingAction: false,
      onClick: () => history.push(`/opportunity/${id}?editing=true`)
    },
    {
      display: 'Duplicate Opportunity',
      disabled: false,
      tooltip: null,
      icon: 'faCopy',
      isPerformingAction: isDuplicatingOpportunity,
      onClick: async () => {
        setIsDuplicatingOpportunity(true);
        const resp = await props.duplicateOpportunity(opportunity);
        if (resp.opportunity) history.push(`/opportunity/${resp.opportunity.id}`);
        setIsDuplicatingOpportunity(false);
      }
    },
    {
      display: opportunity.isArchived ? 'Unarchive' : 'Archive',
      disabled: false,
      tooltip: null,
      icon: opportunity.isArchived ? 'faFolderOpen' : 'faFolder',
      isPerformingAction: false,
      onClick: () => props.updateOpportunity(opportunity, { isArchived: !opportunity.isArchived })
    },
    ...(window.__ADMIN_CONTROL_MODE__
      ? [
          {
            display: 'Log To Console',
            disabled: false,
            tooltip: null,
            icon: 'faUser',
            isPerformingAction: false,
            onClick: () => console.info(opportunity)
          }
        ]
      : [])
  ];
  const goToOpportunity = () => {
    props.setActiveOpportunity(opportunity);
  };

  const nothingDone = statsData.total === 0 && opportunity.plans.length === 0;

  return (
    <Link
      to={`/opportunity/${id}`}
      onClick={goToOpportunity}
      className={cn('opportunity-result-container', {
        'is-active': isActive,
        'nothing-done': nothingDone
      })}
      key={id}
    >
      <div className='opportunity-result-header'>
        <div className='title-container'>
          <div className='title'>{opportunity.title || 'New Opportunity'}</div>
          {isActive && (
            <div className='status-badge-container'>
              <div className='badge-outer'>
                <div className='badge-inner' />
              </div>
              <div className='status'>Currently Live</div>
            </div>
          )}
        </div>
        <div className='timeline-container'>
          <div className='timeline-spine-container'>
            {showTimeline && (
              <>
                <div className={cn('timeline-spine pending', timing.additionalClasses)} />
                <div className={cn('timeline-spine')} style={{ width: `${percentComplete}%` }} />
              </>
            )}
          </div>
          <div className={cn('timeline-label', timing.additionalClasses)}>{timelineLabel}</div>
        </div>
      </div>
      <div className='opportunity-result-body'>
        <div className={cn('media-container', `image-count-${imageCount}`)}>
          {validImageData.map((imageData, idx) => {
            const { image, timestamp } = imageData;
            const timeAgo = timestamp ? getPrettyTimeAgoFromNow(timestamp, { longForm: true }) : null;
            return (
              <div key={image + idx} className='media-img-container'>
                <img src={image} alt={title} />
                {timeAgo && <div className='timestamp'>{timeAgo}</div>}
              </div>
            );
          })}
        </div>
        <div className='main-container'>
          <div className='section'>
            <div className='section-title'>Your Spend</div>
            <div className='section-stats'>
              <div className='section-stat'>
                {isFetchingRequests ? (
                  <div className='value loading'>
                    <Loader size={50} />
                  </div>
                ) : spendData.total ? (
                  <div className='value'>${getPrettyNumber(timing.hasEnded ? spendData.paid : spendData.total)}</div>
                ) : (
                  <div className='value empty'>-</div>
                )}
                <div className={cn('label', { empty: !spendData.total, fetching: isFetchingRequests })}>{timing.hasEnded ? 'Paid' : 'Allocated'}</div>
              </div>
            </div>
          </div>
          <div className='section'>
            <div className='section-title'>
              Results
              {!!statsLastUpdatedAt && (
                <Tooltip message={`Last updated ${getPrettyTimeAgoFromNow(statsLastUpdatedAt, { longForm: true })}`}>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Tooltip>
              )}
            </div>
            <div className='section-stats'>
              {resultStats.map(({ label, value, display, className }) => {
                const additionalClasses = { [className]: true, empty: !value, fetching: isFetchingRequests };
                return (
                  <div key={label} className={cn('section-stat', additionalClasses)}>
                    {isFetchingRequests ? (
                      <div className='value loading'>
                        <Loader size={54} />
                      </div>
                    ) : (
                      <div className={cn('value', additionalClasses)}>{value ? display : '-'}</div>
                    )}
                    <div className={cn('label', additionalClasses)}>{label}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='section'>
            <div className='section-title'>Talent</div>
            <div className='section-stats-grid'>
              {requestStats.map(({ label, value, className }) => {
                const additionalClasses = { [className]: true, empty: !value, fetching: isFetchingRequests };
                return (
                  <div key={label} className={cn('section-stat', additionalClasses)}>
                    {isFetchingRequests ? (
                      <div className={cn('value', additionalClasses)}>
                        <Loader size={24} />
                      </div>
                    ) : (
                      <div className={cn('value', additionalClasses)}>{value || '-'}</div>
                    )}
                    <div className={cn('label', additionalClasses)}>{label}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='section'>
            <div className='section-title'>Recent Activity</div>
            <div className='section-activity'>
              {isFetchingRequests ? (
                <div className='fetching-activities'>Gathering activity...</div>
              ) : (
                activity.map(({ display, icon, img }) => (
                  <div className='activity-item'>
                    <div className='icon-or-image-container'>
                      {img ? (
                        <img src={img} alt={display} />
                      ) : (
                        <div className='activity-icon'>
                          <FontAwesomeIcon icon={icon} />
                        </div>
                      )}
                    </div>
                    <div className='activity-text'>{display}</div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className='footer-actions'>
            {nothingDone && (
              <Tooltip message='This opportunity has no requests or plans yet and may have been created accidentally, click to remove it.'>
                <div
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    actionOptions.find(a => a.display === 'Delete Opportunity').onClick();
                  }}
                  className='primary action show-on-hover'
                >
                  <div>DELETE</div>
                </div>
              </Tooltip>
            )}
            <ActionsOverlay openUp containerClassName='actions-elipsis' options={actionOptions}>
              <FontAwesomeIcon icon={faEllipsisH} />
            </ActionsOverlay>
          </div>
          <div className='view-opportunity-btn'>
            View Opportunity
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      </div>
    </Link>
  );
};

OpportunityResult.propTypes = {
  opportunity: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  isFetchingRequests: PropTypes.bool.isRequired,
  deleteOpportunity: PropTypes.func.isRequired,
  updateOpportunity: PropTypes.func.isRequired,
  duplicateOpportunity: PropTypes.func.isRequired,
  setActiveOpportunity: PropTypes.func.isRequired
};

export default OpportunityResult;
