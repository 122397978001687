import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import _ from 'lodash';
import cn from 'classnames';
import './DiscoverTagGroups.scss';

import { isAdminControlMode } from '../../../Helpers/ui_helpers';
import { isBrandTag, getCompetitorBrands } from '../../../Helpers/user_helpers';

import Loader from '../../Loader/Loader';

const DiscoverTagGroups = props => {
  const { discover, ui, user } = props;

  // Filter and sort Tags
  const adminControlMode = isAdminControlMode(ui);
  const tags = _.filter(_.orderBy(discover?.tagCounts || [], ['type', t => isBrandTag(t, user), t => t.value], ['asc', 'desc']), t => {
    const isActive = props.selectedTags.find(t2 => t.id === t2.id);
    const matchesSearch = !props.curSearchVal || t.value.toLowerCase().includes(props.curSearchVal.toLowerCase());
    if (!matchesSearch && !isActive) return false;
    if (!adminControlMode && t.isHidden) return false;
    return true;
  });

  // Check for competitor brand tags
  const competitorBrands = getCompetitorBrands(user);
  const competitorBrandTagNames = new Set(competitorBrands.map(b => b.name.toLowerCase()));
  const competitorBrandsWithTags = tags.filter(t => competitorBrandTagNames.has(t.value.toLowerCase()));

  const [expandedSections, setExpandedSections] = useState([]);
  const sections = [
    {
      header: 'Brands Promoted',
      tags: _.orderBy(
        tags.filter(tag => tag.type === 'brandpromoter'),
        [tag => competitorBrandsWithTags.includes(tag), t => t.value.toLowerCase()],
        ['desc', 'asc']
      ),
      isFeatured: true,
      initialVisible: _.max([competitorBrands.length, 3])
    },
    {
      header: 'User Types',
      tags: tags.filter(tag => tag.type === 'general')
    },
    {
      header: 'Geography',
      tags: tags.filter(tag => tag.type === 'geo')
    },
    {
      header: 'Social',
      tags: tags.filter(tag => tag.type === 'social')
    },
    {
      header: 'Activity',
      tags: tags.filter(tag => tag.type === 'activity')
    },
    {
      header: 'Collab Offers',
      tags: tags.filter(tag => tag.type === 'collaboration')
    },
    {
      header: 'Product Types',
      tags: tags.filter(tag => tag.type === 'catalog')
    },
    ...(adminControlMode
      ? [
          {
            header: 'Behavior',
            tags: tags.filter(tag => tag.type === 'behavior')
          },
          {
            header: 'Internal',
            tags: tags.filter(tag => tag.type === 'internal')
          }
        ]
      : [])
  ]
    .map(section => ({
      ...section,
      expanded: expandedSections.includes(section.header),
      has_more: section.tags.length > (section.initialVisible || 5),
      initialVisible: section.initialVisible || 5
    }))
    .filter(section => section.tags.length > 0);

  const numResults = props.results.length;
  return (
    <div className='discover-tag-groups-container'>
      <div className='sections'>
        {!sections.length ? (
          <div className='empty-results'>
            {props.isSearchingUsers ? (
              <div className='header'>Searching for creators...</div>
            ) : numResults === 1 ? (
              <div className='header'>Found {props.results[0].name}</div>
            ) : numResults ? (
              <div className='header'>Found {numResults < 10 ? `${numResults} creator${numResults === 1 ? '' : 's'}` : `many creators`}</div>
            ) : (
              <div className='header'>No tags or creators found</div>
            )}
            {!props.isSearchingUsers && (
              <div className='subheader'>
                If you are looking for a list, tag or competitor brand that we currently are not tracking - let us know!
              </div>
            )}
          </div>
        ) : (
          sections.map(section => {
            const toggleExpanded = () => {
              setExpandedSections(section.expanded ? _.without(expandedSections, section.header) : [...expandedSections, section.header]);
              window.__ADD_EVENT__(section.expanded ? 'Discover - Show Less Tags in Group' : 'Discover - Show More Tags in Group', {
                Section: section.header
              });
            };
            return (
              <div key={section.header} className={cn('section', { featured: section.isFeatured })}>
                <div className='section-header'>{section.header}</div>
                {section.expanded && (
                  <div onClick={toggleExpanded} className='show-less-header'>
                    Show Less
                    <FontAwesomeIcon icon={faChevronUp} />
                  </div>
                )}
                <div className='tags'>
                  {section.tags.slice(0, section.expanded ? 1e6 : section.initialVisible).map(tag => {
                    const isActive = !!props.selectedTags.find(t => t.id === tag.id);
                    const toggleTag = () => {
                      props.checkSubscriptionLevelThenRunFn(() => {
                        props.setPage(0);
                        isActive
                          ? props.setSelectedTags(props.selectedTags.filter(t => t.id !== tag.id))
                          : props.setSelectedTags([...props.selectedTags, tag]);
                        !isActive && props.setCurSearchVal('');

                        window.__ADD_EVENT__(isActive ? 'Discover - Unselected Tag' : 'Discover - Selected Tag', {
                          Tag_value: tag.value,
                          Tag_type: tag.type,
                          All_selected_tags: [...props.selectedTags, tag].map(t => t.value).join(', '),
                          Source: 'Sidebar' // Since you can also unselect from the top bar
                        });
                      });
                    };

                    return (
                      <div onClick={toggleTag} className={cn('tag', { active: isActive, loading: props.isSearchingTags })} key={tag.id}>
                        <div className='value'>{tag.value}</div>
                        <div className='count'>{tag.count}</div>
                        <div className='loader-container'>
                          <Loader size={28} />
                        </div>
                      </div>
                    );
                  })}
                </div>
                {section.has_more && (
                  <div onClick={toggleExpanded} className='more'>
                    {section.expanded ? <div className='less-button'>Show Less</div> : <div className='more-button'>Show More</div>}
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

DiscoverTagGroups.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  results: PropTypes.array.isRequired,
  curSearchVal: PropTypes.string.isRequired,
  setCurSearchVal: PropTypes.func.isRequired,
  selectedTags: PropTypes.array.isRequired,
  setSelectedTags: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  isSearchingTags: PropTypes.bool.isRequired,
  isSearchingUsers: PropTypes.bool.isRequired,
  checkSubscriptionLevelThenRunFn: PropTypes.func.isRequired
};

export default DiscoverTagGroups;
