import * as amplitude from '@amplitude/analytics-browser';
import moment from 'moment';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

/*
  While accessing the redux store directly is not recommended, we do it here because we want to access
  the user object globally without requiring it to be passed down through props. Please do not replicate
  this pattern elsewhere in the codebase unless you are sure you need to.
*/
import storeData from '../configureStore';
import { isSimulatingUser, isEUUser } from './user_helpers';

export const initializeAmplitude = () => {
  // Ensure we only do this once per page load
  if (!isAmplitudeTrackingEnabled()) return;

  // Initialize Session Replay
  const user = storeData.store.getState().user;
  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: window.__IS_PROD__ ? 1 : 1, // 100% in prod, 100% in dev for now
    ...(isEUUser(user) ? { serverZone: 'EU' } : {}) // For GDPR compliance
  });
  amplitude.add(sessionReplayTracking);

  // Initialize Base Amplitude Tracking
  // Temporary Remove after 2024-10-12, this is because we are moving from tracking all users to only tracking brands
  const cacheHasClearedAndCanUseSecureKey = moment().isAfter(moment('2024-10-12'));
  const KEY = cacheHasClearedAndCanUseSecureKey
    ? process.env.REACT_APP_AMPLITUDE_KEY
    : window.__IS_PROD__
    ? '4a605611dc5ef13fd159ae83627c4c3f'
    : process.env.REACT_APP_AMPLITUDE_KEY;

  amplitude.init(KEY, null, {
    includeReferrer: true,
    includeUtm: true,
    includeGclid: true
  });
};

export const logAmplitudeEvent = (name, data = {}) => {
  if (!isAmplitudeTrackingEnabled()) return;

  amplitude.logEvent(name, {
    ...data,

    // Extra information to help debug certain cases
    ScreenResolution: `${window.screen.width}x${window.screen.height}` // ex: "1920x1080"
  });
};

export const isAmplitudeTrackingEnabled = reduxUser => {
  /*
    Since Amplitude charges on a per-user basis, we only want to track users who are brands for now,
    soon we will be able to track all logged in users.

    We must pass in the profile if we are doing this in the reducer, as you are not allowed to access
    the redux store directly in reducers.
  */
  const user = reduxUser || storeData.store.getState().user;
  const userProfile = user.profile;

  // Do not track if we are simulating a user
  if (isSimulatingUser(user) && window.__IS_PROD__) return false;

  // Brands are the only users we want to track
  if (userProfile.brand?.id) return true;

  return false;
};

export const syncAmplitudeUserProfile = user => {
  if (!isAmplitudeTrackingEnabled(user)) return;

  // Set User_id to something readable
  const profile = user.profile;
  amplitude.setUserId(profile.brand?.name || profile.username);

  // Set other User Properties
  const identifyObj = new amplitude.Identify();
  identifyObj.set('User_id', profile.id);
  identifyObj.set('Email', profile.email);
  identifyObj.set('Name', profile.name);
  if (profile.brand?.id) {
    identifyObj.set('Brand', profile.brand.name);
    identifyObj.set('Account Manager', profile.brand.account_manager?.name || 'None');
  } else {
    identifyObj.set('Username', profile.username);
    identifyObj.set('True Score', profile.tier.trueScore);
  }
  amplitude.identify(identifyObj);
};
