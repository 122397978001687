import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import './TalentResultStats.scss';

import { getTalentStatBadge, getTalentStatLevel, isPromoterOrRecommended, getTalentStatDisplayValue } from '../../../Helpers/talent_helpers';
import { isSubscribedToFeature, blockOnRequiringSubscription } from '../../../Helpers/subscription_helpers';
import { isAdminControlMode } from '../../../Helpers/ui_helpers';
import { getPrettyNumber } from '../../../Helpers/formatting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import Tooltip from '../../General/Tooltip';

const TalentResultStats = props => {
  const { result, user, ui, rows } = props;
  const {
    instagramAverageStoryViews,
    averageOrderAmount,
    posts_per_week,
    monthly_order_volume,
    conversion_rate,
    giftsAccepted,
    giftsReceived,
    giftsPromoted,
    codesPromoted,
    codesReceived,
    isPromoter,
    volume
  } = result;
  const promoterOrRecommended = isPromoterOrRecommended(result);

  let stats = [
    {
      display: 'Social Following',
      displayType: 'following',
      featureType: 'ANALYTICS',
      level: getTalentStatLevel(result, 'social_following'),
      displayValue: getTalentStatDisplayValue(result, 'social_following')
    },
    {
      display: 'Social Engagement',
      displayType: 'engagement',
      featureType: 'ANALYTICS',
      level: getTalentStatLevel(result, 'social_engagement'),
      displayValue: getTalentStatDisplayValue(result, 'social_engagement')
    },
    {
      display: 'Social Post Frequency',
      displayType: 'frequency',
      featureType: 'ANALYTICS',
      level: getTalentStatLevel(result, 'social_post_frequency'),
      displayValue: posts_per_week ? `${posts_per_week.toFixed(1)} / Week` : '-'
    },
    {
      display: 'Order Volume',
      featureType: promoterOrRecommended ? 'ADVANCED_TALENT_ANALYTICS' : 'ADVANCED_TALENT_ANALYTICS_NON_PROMOTERS',
      level: getTalentStatLevel(result, 'monthly_order_volume'),
      displayValue: monthly_order_volume ? `$${getPrettyNumber(Math.round(monthly_order_volume))} / mo` : '-'
    },
    {
      display: 'Conversion Rate',
      featureType: promoterOrRecommended ? 'ADVANCED_TALENT_ANALYTICS' : 'ADVANCED_TALENT_ANALYTICS_NON_PROMOTERS',
      level: getTalentStatLevel(result, 'conversion_rate'),
      displayValue: conversion_rate ? `${(conversion_rate * 100).toFixed(2)}%` : '-',
      explainer: 'Percentage of clicks resulting in purchases'
    },
    {
      display: 'Gifting Acceptance Rate',
      displayType: 'acceptance_rate',
      isHidden: isPromoter,
      featureType: promoterOrRecommended ? 'ADVANCED_TALENT_ANALYTICS' : 'ADVANCED_TALENT_ANALYTICS_NON_PROMOTERS',
      level: getTalentStatLevel(result, 'gifting_acceptance_rate'),
      displayValue: giftsReceived ? `${giftsAccepted} / ${giftsReceived}` : '-'
    },
    {
      display: 'Gifting Promotion Rate',
      displayType: 'promotion_rate',
      isHidden: isPromoter,
      featureType: promoterOrRecommended ? 'ADVANCED_TALENT_ANALYTICS' : 'ADVANCED_TALENT_ANALYTICS_NON_PROMOTERS',
      level: getTalentStatLevel(result, 'gifting_promotion_rate'),
      displayValue: giftsAccepted ? `${giftsPromoted} / ${giftsAccepted}` : '-'
    },
    {
      display: 'Code Conversion',
      displayType: 'promotion_rate',
      isHidden: !isPromoter,
      featureType: promoterOrRecommended ? 'ADVANCED_TALENT_ANALYTICS' : 'ADVANCED_TALENT_ANALYTICS_NON_PROMOTERS',
      level: getTalentStatLevel(result, 'codes_conversion_rate'),
      displayValue: codesReceived ? `${codesPromoted} / ${codesReceived}` : '-'
    },
    {
      display: 'Non-DTC Volume',
      displayType: 'dtc_volume',
      isHidden: !isPromoter,
      featureType: 'CROSS_RETAILER_ANALYTICS',
      level: getTalentStatLevel(result, 'dtc_volume'),
      levelValue: volume?.indirect ? `$${getPrettyNumber(volume.indirect)}` : null,
      displayValue: volume?.total ? `$${getPrettyNumber(Math.round(volume.indirect))} / $${getPrettyNumber(Math.round(volume.total))}` : '-'
    },
    {
      display: 'Instagram Story Views',
      displayType: 'engagement',
      featureType: 'ANALYTICS',
      level: getTalentStatLevel(result, 'instagram_story_views'),
      displayValue: instagramAverageStoryViews ? `${getPrettyNumber(Math.round(instagramAverageStoryViews))} / story` : '-'
    },
    {
      display: 'Average Order Amount',
      displayType: 'amount',
      featureType: promoterOrRecommended ? 'ADVANCED_TALENT_ANALYTICS' : 'ADVANCED_TALENT_ANALYTICS_NON_PROMOTERS',
      level: getTalentStatLevel(result, 'average_order_amount'),
      displayValue: averageOrderAmount ? `$${getPrettyNumber(Math.round(averageOrderAmount))}` : '-'
    }
  ];

  stats = stats.filter(s => !s.isHidden);
  stats = _.orderBy(stats, s => {
    const isUnlocked = isSubscribedToFeature(user, s.featureType);
    return !isUnlocked ? 1e6 : ['highest', 'higher', 'high', 'medium', 'low', 'lowest', 'na'].indexOf(s.level);
  });

  return (
    <div className='talent-result-stats'>
      {_.chunk(stats, Math.ceil(stats.length / (rows || 2))).map((group, idx) => {
        return (
          <div key={'group' + idx} className='stat-column'>
            {group.map((stat, idx) => (
              <Stat {...props} stat={stat} key={idx} />
            ))}
          </div>
        );
      })}
    </div>
  );
};

const Stat = props => {
  const { stat, user, ui } = props;
  const { level, display, featureType, displayType, displayValue, levelValue, explainer } = stat;

  const isUnlocked = isSubscribedToFeature(user, featureType) || isAdminControlMode(ui);

  const [showingValue, setShowingValue] = useState(false);
  const toggleValueVisibility = () => setShowingValue(!showingValue);
  const warnIfLocked = () => blockOnRequiringSubscription(user, featureType);

  const badge = levelValue || getTalentStatBadge(level).display[displayType || 'general'];
  const displayBadge = displayValue || badge; // If no value is shown
  return (
    <>
      <div className='stat-container'>
        <div className='stat'>
          {isUnlocked && !!explainer && (
            <div className='explainer'>
              <Tooltip message={explainer}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            </div>
          )}
          {display}
        </div>
        <div
          onClick={warnIfLocked}
          onMouseOver={toggleValueVisibility}
          onMouseOut={toggleValueVisibility}
          className={cn('badge', level, { locked: !isUnlocked })}
        >
          {isUnlocked ? (showingValue ? displayBadge : badge) : 'Locked'}
        </div>
      </div>
    </>
  );
};

TalentResultStats.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,

  // Optional UI
  rows: PropTypes.number
};

export default TalentResultStats;
